<template>
	<div>
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1400"
			filename="Lista_de_Precios"
			:pdf-quality="1.5"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			@progress="onProgress($event)"
			@hasStartedGeneration="hasStartedGeneration()"
			@hasGenerated="hasGenerated($event)"
			ref="html2Pdf"
		>
			<section slot="pdf-content">
				<div
					v-for="(pagina, z) in listaPagina"
					:key="z"
					class="relative"
					style="height: 1122px"
				>
					<div class="header"></div>

					<div class="flex justify-between z-20">
						<div class="mt-5 ml-10 w-56">
							<img
								style="width: 100px; height: 67.5px"
								src="../../public/img/logoPequeño.png"
								alt="logo pequeno"
							/>
						</div>
						<div>
							<h1 class="text-3xl pt-10" style="margin-right: 18rem">
								Lista de Precios
							</h1>
						</div>
					</div>

					<div class="flex justify-between pt-5 z-20">
						<div class="ml-10">
							<div class="flex">
								<p class="font-bold">Fecha:</p>
								<p class="text-white">D</p>
								<p>{{ Fecha() }}</p>
							</div>
						</div>
					</div>

					<div class="absolute inset-x-0">
						<div class="flex justify-center mt-3">
							<table id="cotizar" class="table-auto mt-4">
								<thead>
									<tr class="border-b-2 border-black">
										<th class="py-2" :class="clase">Producto</th>
										<th class="py-2" :class="clase">Und</th>
										<th
											v-if="
												$store.state.tipoPDFListaPrecios == 'bs' ||
													$store.state.tipoPDFListaPrecios == 'bsUsd'
											"
											class="py-2"
											:class="clase"
										>
											Precio Bs
										</th>
										<th
											v-if="
												$store.state.tipoPDFListaPrecios == 'usd' ||
													$store.state.tipoPDFListaPrecios == 'bsUsd'
											"
											class="py-2"
											:class="clase"
										>
											Precio USD
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(producto, k) in pagina" :key="k">
										<td class="text-center text-sm">
											{{ firstLetterUpperCase(producto.producto) }}
										</td>
										<td class="text-center text-sm">
											{{ firstLetterUpperCase(producto.unidad) }}
										</td>
										<td
											v-if="
												$store.state.tipoPDFListaPrecios == 'bs' ||
													$store.state.tipoPDFListaPrecios == 'bsUsd'
											"
											class="text-center text-sm"
										>
											{{ formato(producto.precioBs) }}
										</td>
										<td
											v-if="
												$store.state.tipoPDFListaPrecios == 'usd' ||
													$store.state.tipoPDFListaPrecios == 'bsUsd'
											"
											class="text-center text-sm"
										>
											{{ formato(producto.precioUsd) }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="flex justify-center">
						<img
							class="w-5/6"
							src="../../public/img/logoGrande.png"
							alt="logoGrande"
						/>
					</div>
					<div
						class="
              absolute
              flex
              bg-verdeShopper
              h-20
              items-center
              w-full
              inset-x-0
              bottom-0
            "
					>
						<p class="mx-20 text-center items-center text-sm text-white bold">
							DOMICILIO FISCAL AV MOHEDANO EDIF MERCADO MUNICIPAL DE CHACAO,
							NUEVA SEDE PISO MEZZANINA LOCAL DM8 URB CHACAO CARACAS (CHACAO)
							MIRANDA ZONA POSTAL 1080. tel: 0212-262-20-98
						</p>
					</div>
				</div>
			</section>

			<div class="html2pdf__page-break" />
			<!-- Segunda Pagina!!!!!!! -->

			<!--  <section v-if="productosCotizacionT.length > 13" slot="pdf-content">
        <div v-for="(cotizacionPagina, z) in dataPDF" :key="z">
          <div class="relative" style="height: 1122px">
            <div class="header"></div>

            <div class="flex justify-between z-20">
              <div class="mt-5 ml-10">
                <img
                  style="width: 100px; height: 67.5px"
                  src="../../public/img/logoPequeño.png"
                  alt="logo pequeno"
                />
              </div>
            </div>

            <div class="absolute inset-x-0">
              <div class="flex justify-center mx-8 mt-3">
                <table id="cotizarr" class="table-auto mt-4">
                  <thead>
                    <tr class=" border-b-2 border-black">
                      <th class="px-4 py-2">Cantidad</th>
                      <th class="px-4 py-2">Und</th>
                      <th class="px-4 py-2">Producto</th>
                      <th class="px-4 py-2">Precio Bs</th>
                      <th class="px-4 py-2">Precio USD</th>
                      <th class="px-4 py-2">Total Bs</th>
                      <th class="px-4 py-2">Total USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cotizaciont, k) in cotizacionPagina" :key="k">
                      <td class="text-center text-sm">
                        {{ cotizaciont.Cantidad }}
                      </td>
                      <td class=" text-center text-sm">
                        {{ cotizaciont.Unidad }}
                      </td>
                      <td class=" text-center text-sm">
                        {{ cotizaciont.Producto }}
                      </td>
                      <td class="text-center text-sm px-4 py-1">
                        {{ formato(cotizaciont.PrecioUnitarioBs) }}
                      </td>
                      <td class=" text-center text-sm">
                        {{ formato(cotizaciont.PrecioUnitarioD) }}
                      </td>
                      <td class=" text-center text-sm">
                        {{ formato(cotizaciont.MontoCobrarBs) }}
                      </td>
                      <td class=" text-center text-sm">
                        {{ formato(cotizaciont.MontoCobrarD) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="flex justify-center">
              <img
                class="w-5/6 mt-40"
                src="../../public/img/logoGrande.png"
                alt="logoGrande"
              />
            </div>
            <div
              class="absolute flex bg-verdeShopper h-20 items-center w-full inset-x-0 bottom-0"
            >
              <p class="mx-20 text-center items-center text-sm text-white bold">
                DOMICILIO FISCAL AV MOHEDANO EDIF MERCADO MUNICIPAL DE CHACAO,
                NUEVA SEDE PISO MEZZANINA LOCAL DM8 URB CHACAO CARACAS (CHACAO)
                MIRANDA ZONA POSTAL 1080. tel: 0212-262-20-98
              </p>
            </div>
          </div>
        </div>
      </section> -->

			<div class="html2pdf__page-break" />
		</vue-html2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import {
	firstLetterUpperCase,
	formato,
	formatoFecha,
	Fecha
} from "../functions.js";

export default {
	data() {
		return {
			productosCotizacionT: [],
			paginacion: 0,
			dataTotal: {},
			primeraPagina: false,
			dataPDF: [],
			listaPagina: [],
			nombrePDF: "nombre",
			lineasPaginas: 33,
			clase: "px-12"
		};
	},
	mounted() {
		//igualar variables
		/* if (
      this.$store.state.tipoPDFListaPrecios == "bs" ||
      this.$store.state.tipoPDFListaPrecios == "usd"
    ) {
      this.lineasPaginas = 33;
      this.clase = "px-12";
    } else {
      this.lineasPaginas = 27;
      this.clase = "px-8";
    } */
		let numeroPaginas = 0;
		let index = 0;
		numeroPaginas =
			parseInt(this.$store.state.pdfData.length / this.lineasPaginas) + 1;
		//llenado de array para las respectivas páginas del pdf
		for (let i = 0; i < numeroPaginas; i++) {
			this.listaPagina.push([]);
			for (let j = 0; j < this.lineasPaginas; j++) {
				if (this.$store.state.pdfData.length > j + index) {
					this.listaPagina[i].push(this.$store.state.pdfData[j + index]);
				} else {
					break;
				}
			}
			index += 33;
		}
		this.generateReport();
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		Fecha,
		/*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
		generateReport() {
			this.$refs.html2Pdf.generatePdf();
		}
	},

	components: {
		VueHtml2pdf
	}
};
</script>

<style>
#sideBar {
	display: none;
}
#cabecera {
	display: none;
}

.header {
	background-color: #005226;
	width: auto;
	height: 3rem;
}
#subheader {
	display: flex;
}

#cotizacionNombre {
	float: right;
}

#smallLogo {
	float: left;
	padding-top: 0.7rem;
	margin-left: 2rem;
	width: 6.3rem;
	height: 5rem;
}
</style>
