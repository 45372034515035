var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"Lista_de_Precios","pdf-quality":1.5,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"800px"},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},_vm._l((_vm.listaPagina),function(pagina,z){return _c('div',{key:z,staticClass:"relative",staticStyle:{"height":"1122px"}},[_c('div',{staticClass:"header"}),_c('div',{staticClass:"flex justify-between z-20"},[_c('div',{staticClass:"mt-5 ml-10 w-56"},[_c('img',{staticStyle:{"width":"100px","height":"67.5px"},attrs:{"src":require("../../public/img/logoPequeño.png"),"alt":"logo pequeno"}})]),_c('div',[_c('h1',{staticClass:"text-3xl pt-10",staticStyle:{"margin-right":"18rem"}},[_vm._v(" Lista de Precios ")])])]),_c('div',{staticClass:"flex justify-between pt-5 z-20"},[_c('div',{staticClass:"ml-10"},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"font-bold"},[_vm._v("Fecha:")]),_c('p',{staticClass:"text-white"},[_vm._v("D")]),_c('p',[_vm._v(_vm._s(_vm.Fecha()))])])])]),_c('div',{staticClass:"absolute inset-x-0"},[_c('div',{staticClass:"flex justify-center mt-3"},[_c('table',{staticClass:"table-auto mt-4",attrs:{"id":"cotizar"}},[_c('thead',[_c('tr',{staticClass:"border-b-2 border-black"},[_c('th',{staticClass:"py-2",class:_vm.clase},[_vm._v("Producto")]),_c('th',{staticClass:"py-2",class:_vm.clase},[_vm._v("Und")]),(
												_vm.$store.state.tipoPDFListaPrecios == 'bs' ||
													_vm.$store.state.tipoPDFListaPrecios == 'bsUsd'
											)?_c('th',{staticClass:"py-2",class:_vm.clase},[_vm._v(" Precio Bs ")]):_vm._e(),(
												_vm.$store.state.tipoPDFListaPrecios == 'usd' ||
													_vm.$store.state.tipoPDFListaPrecios == 'bsUsd'
											)?_c('th',{staticClass:"py-2",class:_vm.clase},[_vm._v(" Precio USD ")]):_vm._e()])]),_c('tbody',_vm._l((pagina),function(producto,k){return _c('tr',{key:k},[_c('td',{staticClass:"text-center text-sm"},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(producto.producto))+" ")]),_c('td',{staticClass:"text-center text-sm"},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(producto.unidad))+" ")]),(
												_vm.$store.state.tipoPDFListaPrecios == 'bs' ||
													_vm.$store.state.tipoPDFListaPrecios == 'bsUsd'
											)?_c('td',{staticClass:"text-center text-sm"},[_vm._v(" "+_vm._s(_vm.formato(producto.precioBs))+" ")]):_vm._e(),(
												_vm.$store.state.tipoPDFListaPrecios == 'usd' ||
													_vm.$store.state.tipoPDFListaPrecios == 'bsUsd'
											)?_c('td',{staticClass:"text-center text-sm"},[_vm._v(" "+_vm._s(_vm.formato(producto.precioUsd))+" ")]):_vm._e()])}),0)])])]),_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"w-5/6",attrs:{"src":require("../../public/img/logoGrande.png"),"alt":"logoGrande"}})]),_c('div',{staticClass:"\n              absolute\n              flex\n              bg-verdeShopper\n              h-20\n              items-center\n              w-full\n              inset-x-0\n              bottom-0\n            "},[_c('p',{staticClass:"mx-20 text-center items-center text-sm text-white bold"},[_vm._v(" DOMICILIO FISCAL AV MOHEDANO EDIF MERCADO MUNICIPAL DE CHACAO, NUEVA SEDE PISO MEZZANINA LOCAL DM8 URB CHACAO CARACAS (CHACAO) MIRANDA ZONA POSTAL 1080. tel: 0212-262-20-98 ")])])])}),0),_c('div',{staticClass:"html2pdf__page-break"}),_c('div',{staticClass:"html2pdf__page-break"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }